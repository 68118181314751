td {
  font-size: 13px !important;
}

.ToolbarRegular {
  min-height: 40px !important;
}

.SelectedMenu {
  border-left-width: 4px !important;
  border-left-color: #cc0000 !important;
  border-left-style: solid !important;
  color: #cc0000  !important;
  background-color: #F3F3F3 !important;
}

.Menu {
  border-left-width: 4px !important;
  border-left-color: #ffffff;
  border-left-style: solid !important;
  padding-left: 0px !important;
}

.HomePageCard:hover {
  background-color: #eeeeee !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #cc0000  !important;
  border-bottom-style: solid !important;
}

.HomePageCard {
  background-color: #eeeeee !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #ffffff !important;
  border-bottom-style: solid !important;
}

.Widget {
  margin: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  background-color: #eeeeee;
}

.Form {
  margin: 0px;
  padding: 0px;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
}

.FormDetails {
  padding: 20px !important;
  border-radius: 5px;
}

.FormHeader {
  height: 40px !important;
  padding: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(201, 216, 241, 0.15) 30%, rgba(201, 216, 241, 0.25) 60%, rgba(201, 216, 241, 0.4) 100%);
}

.BoldFormHeader {
  height: 40px !important;
  padding: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0288D1 !important;
  color: white !important;
}


.FormFooter {
  height: 40px !important;
  padding: 0px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: linear-gradient(to top, #ffffff 0%, #ecf0f4 100%, #b3bfc9 100%, #e8e9ff 100%, #0288D1 100%);   */
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(201, 216, 241, 0.15) 30%, rgba(201, 216, 241, 0.25) 60%, rgba(201, 216, 241, 0.4) 100%);
}

.FormHeaderOrFooterDetails {
  text-align: center;
  width: 100%;
  font-size: 16px !important;
  font-weight: bold;
}

.BoldFormHeaderDetails {
  text-align: center;
  width: 100%;
  font-size: 16px !important;
  font-weight: bold;
}

.FormFieldFrame {
  border-radius: 5px;
  color: #0288D1;
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 11px;
  border-color: #cccccc;
  border-width: 1px;
}

.FieldFrame {
  border-radius: 5px;
  border-color: #cccccc;
  border-width: 1px;
}

.CheckBoxChecked {
  color: #cc0000 !important;
}

.RadioButtonLabelImpactRating {
  font-size: 13px !important;
  color: #000000 !important;
}

.HorizontalOverFlowContainer {
  overflow-x: hidden !important;
  width: -webkit-fill-available !important;
}

.PaneDividerWrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}